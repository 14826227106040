/**
 * 防抖函数 指定时间内只执行一次
 * @param {Function} fun 防抖回调函数
 * @param {number} delay 延迟时间 默认 200ms
 * @param {boolean} immediate 是否立即执行 默认 false
 */
 export function debounce(
  fun: any,
  delay: number = 200,
  immediate: boolean = false
): () => any {
  let timer: number | null = null;

  return function () {
    const args = arguments;

    if (timer) clearTimeout(timer);
    if (immediate) {
      // @ts-ignore
      if (!timer) fun.apply(this, args);
      timer = setTimeout(() => (timer = null), delay);
    } else {
      timer = setTimeout(() => {
        // @ts-ignore
        fun.apply(this, args);
      }, delay);
    }
  };
}

/**
 * 节流函数 会执行最后一次操作
 * @param {Function} fun 防抖回调函数
 * @param {number} delay 延迟时间 默认 200ms
 */
export function throttle(func: any, delay: number = 200): () => any {
  let prev = Date.now() - delay;
  let timer: number | null = null;

  return function () {
    // @ts-ignore
    const context = this;
    const args = arguments;
    const now = Date.now();

    if (now - prev >= delay && !timer) {
      func.apply(context, args);
      prev = Date.now();
    } else {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      timer = setTimeout(() => {
        func.apply(context, args);
        timer = null;
      }, delay);
    }
  };
}
