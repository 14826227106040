




































































































































import i18n from '@/lang';
import saveBtnGroup from '@/components/saveBtnGroup.vue';

import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { InstitutionVO } from '@/interface/school';
import { addSchoolListApi, getCountrySelect, editSchoolApi, getSchoolDetailApi, getStateSelect, getCitySelect, getInstitutionAllTypeApi, addAnnualMeetingApi, editAnnualMeetingApi } from '@/api/school_api';
import { isValidURL} from '@/utils/validate';
import { UserStoreModule } from '@/store/UserModule';
import { LayoutStoreModule } from '@/store/LayoutModule';
@Component({ name: 'addSchool', components: { saveBtnGroup } })
export default class AddSchool extends Vue {
  // 有值编辑，没有新增
  @Prop({default: ''}) schoolId!: string;
  // 年会学校或普通学校新增/编辑
  @Prop({default: 'normal'}) componentType!: 'normal' | 'annual';
  @Watch('showDetail')
  handleShow(val: any) {
    if(val) {
      this.getCountrySelect();
      this.getInstitutionType();
      if(this.componentType == 'annual' && !this.formRules.countryIds){
        this.$set(this.formRules, 'countryIds', {
          required: true,
          message: i18n.t('countrySelectEmpty'),
          trigger: 'blur',
        })
      } else if(this.componentType == 'normal' && this.formRules.countryIds) {
        this.$delete(this.formRules, 'countryIds')
      }
      if(this.schoolId) {
        this.getSchoolDetail();
      }else{
        this.character=[
          {key:'', value:''},
          {key:'', value:''},
          {key:'', value:''},
          {key:'', value:''},
          {key:'', value:''},
          {key:'', value:''},
        ]
      }
    } else {
      this.clear();
    }
  }
  addSchoolData: InstitutionVO = {
    id: '',
    establishedDate: '',
    name: '',
    nameChn: '',
    nature: '',
    studentCount: '',
    website: '',
    fkAreaCountryId: '',
    fkUserId: '',
    fkAreaStateId: '',
    fkAreaCityId: '',
    fkInstitutionTypeId: '',
    characters: '',
    countryIds: [],
  };
  character: Array<{key:string,value: string}> = [
    {key:'', value:''},
    {key:'', value:''},
    {key:'', value:''},
    {key:'', value:''},
    {key:'', value:''},
    {key:'', value:''},
  ];
  showDetail: boolean = false;
  countryOption: any[] = [];
  stateOption: any[] = [];
  cityOption: any[] = [];
  // 学校性质
  natureList: any = [i18n.t('publicSchool'),i18n.t('privateSchool')]
  formRules: any = {
    name: [
      {
        required: true,
        message: i18n.t('nameEmpty'),
        trigger: 'blur',
      }
    ],
    nameChn: [
      {
        required: true,
        message: i18n.t('nameChnEmpty'),
        trigger: 'blur',
      }
    ],
    // website: [
    //   {
    //     required: true,
    //     message: i18n.t('checkUrl'),
    //     trigger: 'blur',
    //     validator:this.checkUrl
    //   }
    // ],
    fkAreaCountryId: [
      {
        required: true,
        message: i18n.t('countrySelectEmpty'),
        trigger: 'change',
      }
    ],
    fkInstitutionTypeId: [
      {
        required: true,
        message: i18n.t('chooseCategory'),
        trigger: 'change',
      }
    ],
  };
  schoolTypeList = [];
  // 检查网址
  checkUrl(rule: any, value: any, callback: any){
    if(isValidURL(value)){
      return callback()
    }
    if(value===''){
      return callback()
    }
    return callback(new Error(i18n.t('checkUrl') as string))
  }
  get isSupperPermission(){
    return UserStoreModule.isSupperPermission;
  }
  get isPermission(){
    return (str: string)=>{
      if(UserStoreModule.isSupperPermission) return true;
      return this.$route.meta.permission.indexOf(str) >= 0
    }
  }
  // 获取学校类型下拉
  async getInstitutionType(){
    const { data } = await getInstitutionAllTypeApi();
    this.schoolTypeList = data.data;
  }
  // 获取学校详情
  async getSchoolDetail() {
    const { data } = await getSchoolDetailApi(this.schoolId)
    this.addSchoolData = data.data.institutionDto;
    if (this.addSchoolData.characters){
      const character = JSON.parse(this.addSchoolData.characters);
      while(character.length < 6){
        character.push({key:'',value:''})
      }
      this.character = character;
    }
    if(this.addSchoolData.fkAreaCountryId){
      this.getStateSelect(this.addSchoolData.fkAreaCountryId+'');
      if(this.addSchoolData.fkAreaStateId){
        this.getCitySelect(this.addSchoolData.fkAreaStateId+'');
      }
      
    }
  }
  // 获取国家下拉框
  async getCountrySelect() {
    const { data } = await getCountrySelect();
    this.countryOption = data.data;
  }
  // 获取州省下拉
  async getStateSelect(val: string){
    const { data } = await getStateSelect(val);
    this.stateOption = data.data;
  }
  // 获取城市
  async getCitySelect(val: string){
    const {data}:any=await getCitySelect(val);
    this.cityOption=data.data;
  }
  // 州省改变
  onStateChange(val: string){
    this.addSchoolData.fkAreaCityId = '';
    this.getCitySelect(val);
  }
  // 国家改变
  async onCountryChange(val: string){
    this.addSchoolData.fkAreaStateId = '';
    this.addSchoolData.fkAreaCityId = '';
    this.cityOption=[];
    this.getStateSelect(val);
  }
  clear() {
    (this.$refs.schoolForm as any).resetFields();
    this.addSchoolData = {
      id: '',
      establishedDate: '',
      name: '',
      nameChn: '',
      nature: '',
      studentCount: '',
      website: '',
      fkAreaCountryId: '',
      fkUserId: '',
      characters: '',
      fkAreaStateId: '',
      fkAreaCityId:'',
    };
    this.character = [];
  }
  // 取消
  cancelForm() {
    this.showDetail = false;
  }
  // 确定
  async submitForm(formStr: any) {
    const el: any = this.$refs[formStr];
    el.validate((valid: any)=> {
      if(valid) {
        this.addSchoolData.fkAreaStateId = this.addSchoolData.fkAreaStateId || '';
        this.addSchoolData.characters = JSON.stringify(this.character.filter(item=>{
          return item.key || item.value;
        }));
        (this.$refs.saveBtnRef as any).loadingTrue();
        if(this.schoolId) {
          this.componentType == 'normal'? this.editSchool(this.addSchoolData): this.editAnnualSchool(this.addSchoolData);
        } else {
          // 新增当前创建人的userId
          this.addSchoolData.fkUserId = UserStoreModule.userInfo.id
          this.componentType == 'normal'? this.addSchool(this.addSchoolData): this.addAnnualSchool(this.addSchoolData);
        }
      }
    })
  }
  // 编辑学校
  editSchool(data:any) {
    editSchoolApi(data).then(()=> {
      this.$message({ type: 'success', message: i18n.t('editSuccess') as string });
      this.showDetail = false;
      this.$emit('refresh');
      (this.$refs.saveBtnRef as any).loadingFalse();
    }).catch(err=>{
      this.$message.error(i18n.t('editFail') as string);
      (this.$refs.saveBtnRef as any).loadingFalse();
    })
  }

  // 新增学校
  addSchool(data: any) {
    data.dataType = 0;
    addSchoolListApi(data).then((ret)=> {
      if(ret.data.code == 1003){
        // 超管不显示侧边栏
        if(!UserStoreModule.isSupperPermission) {
          LayoutStoreModule.addAsideMenubySchool(ret.data.data);
        }
        this.$message({ type: 'success', message: i18n.t('addSuccess') as string });
        this.showDetail = false;
        this.$emit('refresh');
        (this.$refs.saveBtnRef as any).loadingFalse();
      }
    }).catch(err=>{
      this.$message.error(i18n.t('addFail') as string);
      (this.$refs.saveBtnRef as any).loadingFalse();
    })
  }
  // 新增年会学校
  addAnnualSchool(data:any){
    data.dataType = 1;
    addAnnualMeetingApi(data).then((ret)=> {
      if(ret.data.code == 1003){
        this.$message({ type: 'success', message: i18n.t('addSuccess') as string });
        this.showDetail = false;
        this.$emit('refresh');
        (this.$refs.saveBtnRef as any).loadingFalse();
      }
     
    }).catch(err=>{
      this.$message.error(i18n.t('addFail') as string);
      (this.$refs.saveBtnRef as any).loadingFalse();
    })
  }
  // 编辑年回学校
  editAnnualSchool(data: any){
    data.dataType = 1;
    editAnnualMeetingApi(data).then(()=> {
      this.$message({ type: 'success', message: i18n.t('editSuccess') as string });
      this.showDetail = false;
      this.$emit('refresh');
      (this.$refs.saveBtnRef as any).loadingFalse();
    }).catch(err=>{
      this.$message.error(i18n.t('editFail') as string);
      (this.$refs.saveBtnRef as any).loadingFalse();
    })
  }
  addCharacter(){
    this.character.push({
      key: '',
      value: ''
    });
  }
  deleteCharacter(index:number){
    this.character.splice(index,1)
  }
}
