








import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
@Component({
  name: 'saveBtnGroup',
})
export default class extends Vue {
  @Prop({ type: String, default: '' }) formStr!: string;

  // loading
  loading:boolean=false;

  @Emit('submitForm')
  saveFunction() {
    return this.formStr;
  }

  cancelFunction() {
    this.$emit('cancelForm');
  }
  // loading为true
  loadingTrue(){
    this.loading=true;
  }
  // loading为false
  loadingFalse(){
    this.loading=false;
  }
}
